import './styles/main.scss'
import * as THREE from 'three'

import logoLrg from './assets/images/logo.svg'
const logoLargeImg = document.getElementById('logoLargeImg')
logoLargeImg.src = logoLrg

import logoSml from './assets/images/logo.svg'
const logoSmallImg = document.getElementById('logoSmallImg')
logoSmallImg.src = logoSml

import maraImg from './assets/images/mara.jpg'
const backgroundImg = document.getElementById('backgroundImg')
backgroundImg.src = maraImg